import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';
import FeaturesCaseStudyItem from 'components/molecules/FeaturesCaseStudyItem';

const FeaturesCaseStudy = ({ data, className }) => {
  const { features_list: list, caption } = data || {};
  const { title } = caption;

  const featuresData =
    list &&
    list.map((item, index) => (
      <FeaturesCaseStudyItem data={item} key={index} />
    ));

  return (
    <Section className={cn('features-case-study', [className])}>
      <Container>
        <Row>
          <Column className="offset-lg-2" lg="9" xl="7">
            <div className="features-case-study__block">
              <Text tag="h4" className="features-case-study__title">
                {title}
              </Text>
              <div className="features-case-study__content">{featuresData}</div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

FeaturesCaseStudy.defaultProps = {
  className: '',
};

FeaturesCaseStudy.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default FeaturesCaseStudy;
